const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "People",
        route: "/search/people",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-people",
      },
      {
        heading: "Businesses",
        route: "/search/businesses",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-briefcase-fill",
      },
      {
        heading: "Local Businesses",
        route: "/builder",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-briefcase",
      },
      {
        heading: "Instagram",
        route: "/builder",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-instagram",
      },
    ],
  },
  {
    heading: "Tools",
    route: "/crafted",
    pages: [
      {
        heading: "Site Scraper",
        route: "/tools/site-scraper",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-globe",
      },
      {
        heading: "My Scrapes",
        route: "/tools/my-scrapes",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-globe",
      },
      {
        heading: "Email Verifier",
        route: "/tools/email-verifier",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-envelope",
      },
      {
        heading: "Email Verifier 2",
        route: "/tools/email-verifier-2",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-envelope",
      },
    ],
  },
  {
    heading: "Lists",
    route: "/lists",
    pages: [
      {
        heading: "Business Lists",
        route: "/lists/business",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-briefcase"
      },
      {
        heading: "People Lists",
        route: "/lists/people",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-people"
      }
    ]
  }
];

export default DocMenuConfig;
